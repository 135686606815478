export default [

    {   
        id: 1,
        title: 'MOBILE PORTAL',
        link:  'https://linkedin.com/in/tony-o-softwaredev/',
        image:'portal.png',
        category: '.mobile',
    },
    {   
        id: 2,
        title: 'HELB APP',
        link:  'https://linkedin.com/in/tony-o-softwaredev/',
        image:'helbapp.png',
        category: '.mobile',
    },
    {   
        id: 3,
        title: 'HELB PORTAL',
        link:  'https://linkedin.com/in/tony-o-softwaredev/',
        image:'student.png',
        category: '.web',
    },
    {   
        id: 4,
        title: 'HSBF PORTAL',
        link:  'https://linkedin.com/in/tony-o-softwaredev/',
        image:'hsbf.png',
        category: '.web',
    },
    {   
        id: 5,
        title: 'HELB USSD APP',
        link:  'https://linkedin.com/in/tony-o-softwaredev/',
        image:'helbapp.png',
        category: '.mobile',
    },
    {   
        id: 6,
        title: 'KEY MICROFINANCE IPRS',
        link:  'https://linkedin.com/in/tony-o-softwaredev/',
        image:'keymicro.png',
        category: '.web',
    },
  

]