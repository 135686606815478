import React from 'react'
import Wow from 'wowjs'
import { useEffect } from 'react';


export default function Services() {
    var wow = new Wow.WOW({live: false});

    useEffect(()=>{
      wow.init();
    }, )
   var fill = '#ff0000';
  return (
    <div className='services pt-4 pb-1 px-2 d-flex justify-content-center' id='services'>
      <div className='col-md-11 col-lg-10 col-xl-9 bg-succes pt-5 '>
      <section className='about-header'>
        <h4 className='header-text text-uppercase wow slideInLeft'>What I do</h4>
    </section>
    <section className='mt-3 pt-3 pb-2 cards d-flex justify-content-evenly flex-wrap align-items-center gap-md-3 gap-lg-4 gap-2'>
    
          <div className='service-card wow slideInUp p-2'>
            <div className='card-header heading d-flex flex-column justify-content-center align-items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill} class="bi bi-phone" viewBox="0 0 16 16">
              <path d="M11 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h6zM5 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H5z"/>
              <path d="M8 14a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"/>
            </svg>
              <p className='card-title heading'>USSD Services</p>
              </div>
            <div className='card-description bg-dange pt-2 text-center paragraph'>
                <p>
                  Make your services available by developing a USSD application for you.
                </p>
            </div>
          </div>  
        <div className='service-card wow slideInUp p-2'>
            <div className='card-header heading d-flex flex-column justify-content-center align-items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill}  className="bi bi-globe" viewBox="0 0 16 16">
              <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/>
            </svg>
              <p className='card-title heading'>ERP Development</p>
              </div>
            <div className='card-description bg-dange pt-2 text-center paragraph'>
                <p>
                Strong proficiency in relevant programming languages essential for ERP development.                </p>
            </div>
            
        </div>  
        <div className='service-card wow slideInUp p-2'>
            <div className='card-header heading d-flex flex-column justify-content-center align-items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill}  className="bi bi-android2" viewBox="0 0 16 16">
                <path d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z"/>
              </svg>
              <p className='card-title heading'>Mobile Development</p>
              </div>
            <div className='card-description bg-dange pt-2 text-center paragraph'>
                <p>
                  Build, test and deploy cross platform mobile & WEB applications.
                </p>
            </div>
            
        </div>  
        <div className='service-card wow slideInUp p-2'>
            <div className='card-header heading d-flex flex-column justify-content-center align-items-center gap-2'>
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill={fill}  class="bi bi-palette-fill" viewBox="0 0 16 16">
              <path d="M12.433 10.07C14.133 10.585 16 11.15 16 8a8 8 0 1 0-8 8c1.996 0 1.826-1.504 1.649-3.08-.124-1.101-.252-2.237.351-2.92.465-.527 1.42-.237 2.433.07zM8 5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm4.5 3a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zM5 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6.5a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"/>
            </svg>
              <p className='card-title heading'>Design</p>
              </div>
            <div className='card-description bg-dange pt-2 text-center paragraph'>
                <p>
                  Design high quality logos, UX/UI designs, posters and flyers that align with your needs and brand.
                </p>
            </div>
            
        </div>  
        
        
    </section>
      </div>
    
    </div>
  )
}
